<template>
  <div class="enjoy">
    <div class="enjoytitle">
      <h3>让孩子享受写字乐趣</h3>
      <p>专注5-12岁孩子写字教育</p>
    </div>
    <div class="enjoymain">
      <div class="enjoydiv" v-for="(item,index) in enjoyList" :key="index">
        <img class="enjoyimg" :src="item.url"/>
        <p class="maintext">{{item.text1}}</p>
        <p class="maintexts">{{item.text2}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      enjoyList: [
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/homeImg1.png",
          text1: "全国知名书法家教学",
          text2: "龚金夫，浙江省硬笔书法家协会理事，超过20年的书法教学经验"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/enjoy2.png",
          text1: "自主研发课程体系",
          text2:
            "自主研发的科学系统课程内容，真人一对一三维立体观看教学+辅导老师。笔画入门、单字、成语到古诗组成，课程编排科学有效。"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/enjoy3.png",
          text1: "一对一闪电辅导",
          text2:
            "辅导老师均为本科书法专业或书法教学3年以上，当天作业1对1 及时点评辅导，问题不过夜，每时每刻老师就在身边"
        }
      ]
    };
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: MicrosoftYaHei;
}
.enjoy {
  width: 1180px;
  height: 606px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.enjoytitle {
  width: 504px;
  height: 106px;
}
h3 {
  font-size: 56px;
  font-weight: 500;
}
.enjoytitle p {
  font-size: 26px;
}
.enjoymain {
  height: 390px;
  width: 1180px;
  display: flex;
  justify-content: space-between;
}
.enjoydiv {
  height: 390px;
  width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  overflow: hidden;
  border: none;
  outline: none;
}
.enjoyimg {
  height: 178px;
  width: 370px;
  background-color: #e5e5e5;
  border: none;
}
.maintext {
  width: 250px;
  height: 21px;
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
  color: #515558;
}
.maintexts {
  height: 50px;
  width: 298px;
  font-size: 14px;
  line-height: 35px;
  margin-top: 14px;
  text-align: left;
  color: #a2a2a8;
}
</style>

<template>
  <div class="system">
    <p class="title">全面的课程体系</p>
    <div class="ladder">
      <div class="topladder">
        <div class="ladder1">
          <h1>启蒙</h1>
          <ul class="ul">
            <li>认识运笔，熟练笔画，学会笔法基础,构架基础</li>
            <li>初步认识体验书写过程</li>
          </ul>
        </div>
        <div class="ladder2">
          <h1>初级上</h1>
          <ul class="ul">
            <li>学习运笔，熟练笔画，学会笔法的基本表达，基础架构平衡</li>
            <li>初步认识体验书写过程，掌握简单书写</li>
          </ul>
        </div>
        <div class="ladder3">
          <h1>初级下</h1>
          <ul class="ul">
            <li>比较熟练掌握简单的笔法，对独体结构一个有效巩固</li>
            <li>进一步熟练基本笔法、独体字、复合字，解题上更加深入认识</li>
          </ul>
        </div>
        <div class="ladder4">
          <h1>中级上</h1>
          <ul class="ul">
            <li>各种复杂笔法练习训练，学会复合字体构架</li>
            <li>对字体书写规律了解，简单成语创作</li>
          </ul>
        </div>
        <div class="ladder5">
          <h1>中级下</h1>
          <ul class="ul">
            <li>对应所有笔画进行，进一步加强复合字体构架规律</li>
            <li>对字体熟练了解，学会深入组合书写，简单诗歌创作</li>
          </ul>
        </div>
        <div class="ladder6">
          <h1>高级上</h1>
          <ul class="ul">
            <li>了解钢笔笔法在各类字体中的变化运用，构架规律构架体系</li>
            <li>对字体笔法构架比较了解，学会深入诗歌作品书写；书写工具转变</li>
          </ul>
        </div>
        <div class="ladder7">
          <h1>高级下</h1>
          <ul class="ul">
            <li>熟练具体笔法在各类字体中的变化运用，构架规律构架体系熟练化</li>
            <li>对章法有一个比较好的理解；能写出比较系统的好字</li>
          </ul>
        </div>
      </div>
      <div class="bottomladder">
        <p class="bottomtext">根据每个孩子的学习情况、接受能力，河小象任课老师会为每一个小朋友制定不同的学习计划</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style scoped>
* {
  font-family: MicrosoftYaHei;
  margin: 0;
  padding: 0;
}
.system {
  width: 1558px;
  height: 693px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
}
h1 {
  font-size: 30px;
  color: white;
  line-height: 60px;
  margin-top: 5px;
}
.ladder5 .ul {
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  line-height: 30px;
  width: 160px;
  height: 129px;
  text-align: left;
  margin-top: 25px;
}
.ladder6 .ul {
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  line-height: 30px;
  width: 160px;
  height: 129px;
  text-align: left;
  margin-top: 30px;
}
.ladder7 .ul {
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  line-height: 30px;
  width: 160px;
  height: 129px;
  text-align: left;
  margin-top: 35px;
}
.ul {
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  line-height: 30px;
  width: 160px;
  height: 129px;
  text-align: left;
  margin-top: 15px;
}
li {
  margin-bottom: 20px;
}
.title {
  width: 390px;
  line-height: 55px;
  font-size: 55px;
  font-family: MicrosoftYaHei;
  color: rgba(81, 85, 88, 1);
  font-weight: 500;
}
.ladder {
  width: 1558px;
  height: 566px;
  display: flex;
  flex-direction: column;
  transform: scale(0.757);
}
.topladder {
  height: 495px;
  width: 1558px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.bottomladder {
  width: 1558px;
  height: 71px;
  border-radius: 0 0 40px 40px;
  background-color: #ff5970;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomtext {
  font-size: 16px;
  color: white;
}
.ladder1 {
  border-radius: 42px 0 0 0;
  width: 220px;
  height: 244px;
  background-color: #ff877a;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ladder2 {
  border-radius: 42px 0 0 0;
  width: 220px;
  height: 284px;
  background-color: #fe9d70;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ladder3 {
  border-radius: 42px 0 0 0;
  width: 220px;
  height: 324px;
  background-color: #fe9d70;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ladder4 {
  border-radius: 42px 0 0 0;
  width: 220px;
  height: 364px;
  background-color: #6cd4da;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ladder5 {
  border-radius: 42px 0 0 0;
  width: 220px;
  height: 404px;
  background-color: #6cd4da;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ladder6 {
  border-radius: 42px 0 0 0;
  width: 220px;
  height: 444px;
  background-color: #59c4f3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ladder7 {
  border-radius: 42px 0 0 0;
  width: 220px;
  height: 484px;
  background-color: #59c4f3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
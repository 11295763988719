<template>
  <div class="homeIndex">


      <div class="ele">
        <el-carousel indicator-position="none" arrow="never" style="height:708px">
          <el-carousel-item v-for="(item,index) in homeBgList" :key="index" style="height:708px">
          <div :class="item.class">
            <div class="picture">
              <img :src="item.url" alt="" class="pictureS">
            </div>
          </div>
          </el-carousel-item>
        </el-carousel>

          <!-- 预约部分 -->
            <div class="home">
              <div class="classBox">
                <div class="fillInBox">
                  <p class="fillP">免费领取</p>
                  <p class="fillP">价值199元的8天体验课程</p>
                </div>
                <div class="fillInForm">
                    <div class="input1" :style="namenull">
                      <input  v-model="name" :placeholder="cname" @focus="disappear" @blur="appear" />
                    </div>
                    <div class="input1" :style="gradenull"  @click.stop="gradeshow=!gradeshow">
                      <input  v-model="grade" placeholder="请选择孩子的年级" disabled="disabled" />
                      <!-- 为了可触发下拉菜单的面积大一点加一个div -->
                      <div class="imgchoose">
                        <div  class="downimg"></div>
                      </div>
                    </div>
                    <div class="input1" :style="cellphonenull">
                      <span class="areacode"><p>+86</p></span>
                      <input v-model="cellphone" :placeholder="cphone"  @focus="pdisappear" @blur="pappear"/>
                    </div>
                </div>
                <button class="fillBtn" @click.stop="order">立即预约</button>
              </div>
              <!-- 下拉菜单 -->
              <transition name="select">
                <div class="select" v-show="gradeshow">
                  <div class="gradeList" >
                    <div  @click="selectgrade(index)" v-for="(item,index) in gradeList" :key="index">{{item.label}}</div>
                  </div>
                </div>
              </transition>
              <transition name="slide-fade">
                <div class="message" v-if="showMessage">{{message}}</div>
              </transition>

            </div>

      </div>
    <!-- 预约提示框 -->

    <div class="top_video_container">
      <p class="top_video_title">两分钟了解河小象</p>
      <div class="top_video">
        <video class="video-player" controls="" name="media" controlslist="nodownload">
          <source
          src="https://v.hexiaoxiang.com/9d9333e5fe994b9fa03f77dc457e1c50/08583f9dfbc5407296c0b72588d53711-5287d2089db37e62345123a1be272f8b.mp4"
          type="video/mp4"></video>
      </div>
    </div>
    <!-- 让孩子享受写字乐趣 -->
    <div class="indexEnjoy">
        <enjoy></enjoy>
    </div>
    <div class="indexEnjoys">
        <system></system>
    </div>
    <div class="indexEnjoys">
      <div class="science">
        <div class="sciencetitles">
          <p class="sciencetitle">科学的学习系统</p>
          <p class="sciencesubtitle">让练字更有效</p>
        </div>
          <div class="process">
            <div class="processdiv" v-for="(item,index) in scienceList" :key="index">
              <img class="processimg" :src="item.url"/>
              <p class="processtext">{{item.text1}}</p>
              <p class="processtips">{{item.text2}}</p>
            </div>
          </div>
      </div>
    </div>
    <div class="indexEnjoys">
        <works></works>
    </div>
    <div class="indexreview">
        <div class="sciencetitles">
          <p class="sciencetitle">家长心声</p>
          <p class="sciencesubtitle">更多河象之星故事，请关注河象公众号进行查看</p>
        </div>
      <review></review>
    </div>
    <div class="indexfoot">
      <homeAppdownload></homeAppdownload>
    </div>
    <div class="footfix">
        <div class="footorders">
          <div class="footorderimg">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/footPeople.png" class="orderfootimg"/>
          </div>
          <div class="bigfootorder">
              <div class="footorder">
                <div class="footfillInForm">
                  <div class="footinput1" >
                    <div class="group">
                      <input  v-model="name" :placeholder="cname" @focus="disappear" @blur="appear" />
                      <transition name="slide-fade">
                        <div class="footmessage1" v-if="footNameNull">名字不能为空</div>
                      </transition>
                    </div>
                  </div>
                  <div class="footinput1" >
                    <div class="group">
                      <span class="areacode1">+86</span>
                      <input v-model="cellphone" :placeholder="cphone"  @focus="pdisappear" @blur="pappear"/>
                      <transition name="slide-fade">
                        <div class="footmessage2" v-if="footCelNull">手机号不能为空</div>
                      </transition>
                      <transition name="slide-fade">
                        <div class="footmessage2" v-if="footCelWrong">请输入正确的手机号</div>
                      </transition>
                       <transition name="slide-fade">
                        <div class="footmessage2" v-if="orderCel">此手机号已预约</div>
                      </transition>
                    </div>
                  </div>
                  <div class="footinput1"  @click.stop="footgradeshow=!footgradeshow">
                    <div class="group">
                      <input  v-model="grade" placeholder="请选择孩子的年级" disabled="disabled" />
                      <transition name="slide-fade">
                        <div class="footmessage1" v-if="footGradeNull">请选择年级</div>
                      </transition>
                        <div class="footselect" v-show="footgradeshow">
                          <div class="footgradeList" >
                            <div  @click.stop="footselectgrade(index)" v-for="(item,index) in gradeList" :key="index">{{item.label}}</div>
                          </div>
                        </div>
                      <div class="imgchoose">
                        <div  class="upimg"></div>
                      </div>
                    </div>
                  </div>
            </div>
            <button class="footfillBtn" @click.stop="footOrder">立即预约</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 预约提示框 -->
    <div class="orderTip" v-if="showOrderInfo">
      <h1 class="orderTitle">{{orderTitle}}</h1>
      <p class="ordermessage">{{orderMsg}}</p>
      <div class="closeOrder" @click.stop="showOrderInfo=false"></div>
    </div>
  </div>
</template>

<script>
import enjoy from "./enjoy";
import system from "./system";
import works from "./works";
import review from "./review";
import foot from "./foot";
import homeAppdownload from "./homeAppdownload";
export default {
  name: "home",
  components: { enjoy, system, works, review, homeAppdownload, foot },
  data() {
    return {
      name: null,
      footgradeshow: false,
      grade: null,
      footNameNull: false,
      footCelNull: false,
      footCelWrong: false,
      footGradeNull: false,
      cellphone: null,
      gradeshow: false,
      showMessage: false,
      orderCel: false,
      message: "",
      showOrderInfo: false,
      orderTitle: "",
      orderMsg: "",
      namenull: "",
      gradenull: "",
      cellphonenull: "",
      cname: "孩子的名字",
      cphone: "请输入您的手机号",
      gradenum: 0,
      videoUrl: 'https://v.hexiaoxiang.com/9d9333e5fe994b9fa03f77dc457e1c50/08583f9dfbc5407296c0b72588d53711-5287d2089db37e62345123a1be272f8b.mp4',
      scienceList: [
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/scientist1.png",
          text1: "每天视频学习",
          text2:
            "每天仅需几分钟，真人名师一对一教学，三维立体学习，不错过每个学习细节。"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/scientist2.png",
          text1: "打卡写字",
          text2: "采用河小象专用铅笔和练字纸，边学边练，每日打卡排名激励学习。"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/scientist3.png",
          text1: "完善的督学系统",
          text2:
            "针对每份作业，老师针对性及时点评，小班教学，任何学习疑问，老师都能及时解答。"
        }
      ],
      gradeList: [
        {
          value: "1",
          label: "一年级"
        },
        {
          value: "2",
          label: "二年级"
        },
        {
          value: "3",
          label: "三年级"
        },
        {
          value: "4",
          label: "四年级"
        },
        {
          value: "5",
          label: "五年级"
        },
        {
          value: "6",
          label: "六年级"
        },
        {
          value: "7",
          label: "小班"
        },
        {
          value: "8",
          label: "中班"
        },
        {
          value: "9",
          label: "大班"
        },
        {
          value: "10",
          label: "其他"
        }
      ],
      homeBgList: [
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/topPeople.png",
          class: "hB1"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/tophome.png",
          class: "hB2"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/topPeople.png",
          class: "hB1"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/tophome.png",
          class: "hB2"
        }
      ]
    };
  },
  mounted() {
    //点击任何地方都能取消输入的焦点
    document.addEventListener("click", this.hidelist);
    var that = this;
    that.passNo();
    //根据滑动距离判断头部样式和底部样式
    window.onscroll = function() {
      var t = document.documentElement.scrollTop || document.body.scrollTop;
      if (t >= 645) {
        that.passYes();
        if (t >= 648) {
          document.getElementsByClassName("footfix")[0].style.display = "block";
        } else {
          document.getElementsByClassName("footfix")[0].style.display = "none";
        }
      } else {
        document.getElementsByClassName("footfix")[0].style.display = "none";
        that.passNo();
      }
    };
    let key = localStorage.getItem("key");
  },
  created() {
    console.log("PC");
  },
  methods: {
    passYes() {
      this.$emit("changeHeader");
    },
    passNo() {
      this.$emit("notChangeHeader");
    },
    //头部下拉选择年级
    selectgrade(index) {
      this.gradeshow = false;
      this.grade = this.gradeList[index].label;
    },
    //底部下拉选择年级
    footselectgrade(index) {
      this.footgradeshow = false;
      this.grade = this.gradeList[index].label;
    },
    //聚焦时清空名字
    disappear() {
      this.cname = " ";
    },
    //失去焦点时恢复默认输入为“孩子的名字”
    appear() {
      this.cname = "孩子的名字";
    },
    //同上，手机
    pdisappear() {
      this.cphone = " ";
    },
    //同上，手机
    pappear() {
      this.cphone = "请输入您的手机号";
    },
    //点击其它地方时清空预约的input
    hidelist() {
      if (this.gradeshow == true) {
        this.gradeshow = false;
      }
      if (this.footgradeshow == true) {
        this.footgradeshow = false;
      }
    },
    //名字为空时变红
    nullname() {
      this.namenull = "border:1px red solid";
      setTimeout(() => {
        this.namenull = "";
      }, 1500);
    },
    //同上年级
    nullgrade() {
      this.gradenull = "border:1px red solid";
      setTimeout(() => {
        this.gradenull = "";
      }, 1500);
    },
    //同上电话
    nullcellphone() {
      this.cellphonenull = "border:1px red solid";
      setTimeout(() => {
        this.cellphonenull = "";
      }, 1500);
    },
    //设置message
    setMessage() {
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 1500);
    },
    closeFoot() {
      setTimeout(() => {
        this.footNameNull = false;
        this.footCelNull = false;
        this.footGradeNull = false;
        this.footCelWrong = false;
        this.orderCel = false;
      }, 1500);
    },
    // 预约
    order() {
      let nick_name = this.name;
      let array = [
        "",
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "小班",
        "中班",
        "大班",
        "其他"
      ];
      this.gradenum = array.indexOf(this.grade);
      let grade = this.gradenum;
      let cellphone = this.cellphone;
      let source = this.getQueryString("source") || "公司"; //来源
      if (nick_name === null || nick_name === "") {
        this.message = "姓名不能为空";
        this.setMessage();
        this.nullname();
        return;
      }
      if (grade == -1 || grade === "") {
        this.message = "年级不能为空";
        this.setMessage();
        this.nullgrade();
        return;
      }
      if (cellphone == null || cellphone === "") {
        this.message = "手机号不能为空";
        this.setMessage();
        this.nullcellphone();
        return;
      }
      if (!this.isPoneAvailable(cellphone)) {
        this.message = "请输入正确的手机号";
        this.setMessage();
        this.nullcellphone();
        return;
      }
      grade = grade - 0;
      let params = { nick_name, grade, cellphone, source };
      let that = this;
      this.axios
        .post("/api/user/add/info", params)
        .then(res => {
          if (res.data.code == 20000) {
            that.orderTitle = "预约成功";
            that.orderMsg =
              "稍后我们会有老师来联系您激活课程，请保持电话畅通！";
            that.showOrderInfo = true;
            console.log(ok);
          } else {
            if (res.data.code == 20001) {
              that.message = "此手机号已预约";
              that.setMessage();
              return;
            }
            if (res.data.code == 50000) {
              that.orderTitle = "预约失败";
              that.orderMsg = "服务端错误";
              that.showOrderInfo = true;
              return;
            }
            that.message = res.data.errMsg;
            that.setMessage();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getQueryString(name) {
      let reg = `(^|&)${name}=([^&]*)(&|$)`;
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 判断是否为手机号
    isPoneAvailable: function(pone) {
      var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!myreg.test(pone)) {
        return false;
      } else {
        return true;
      }
    },
    //底部预约
    footOrder() {
      let nick_name = this.name;
      let array = [
        "",
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "小班",
        "中班",
        "大班",
        "其他"
      ];
      this.gradenum = array.indexOf(this.grade);
      let grade = this.gradenum;
      let cellphone = this.cellphone;
      let source = this.getQueryString("source") || "公司"; //来源
      if (nick_name === null || nick_name === "") {
        this.footNameNull = true;
        this.closeFoot();
        return;
      }
      if (cellphone == null || cellphone === "") {
        this.footCelNull = true;
        this.closeFoot();
        return;
      }
      if (!this.isPoneAvailable(cellphone)) {
        this.footCelWrong = true;
        this.closeFoot();
        return;
      }
      if (grade == -1 || grade === "") {
        this.footGradeNull = true;
        this.closeFoot();
        return;
      }
      grade = grade - 0;
      let params = { nick_name, grade, cellphone, source };
      let that = this;
      this.axios
        .post("/api/user/add/info", params)
        .then(res => {
          if (res.data.code == 20000) {
            that.orderTitle = "预约成功";
            that.orderMsg =
              "稍后我们会有老师来联系您激活课程，请保持电话畅通！";
            that.showOrderInfo = true;
            console.log(ok);
          } else {
            if (res.data.code == 20001) {
              that.message = "此手机号已预约";
              that.orderCel = true;
              that.closeFoot();
              that.setMessage();
              return;
            }
            if (res.data.code == 50000) {
              that.orderTitle = "预约失败";
              that.orderMsg = "服务端错误";
              that.showOrderInfo = true;
              return;
            }
            that.message = res.data.errMsg;
            that.setMessage();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    //
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.imgchoose {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.select {
  position: absolute;
  width: 296px;
  height: 314px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 245px;
  right: 42px;
  border: #e3ebf6 1px solid;
  background-color: white;
}
.gradeList {
  width: 280px;
  height: 288px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.gradeList div {
  width: 280px;
  line-height: 31px;
  text-align: left;
  cursor: pointer;
}
/* 两分钟了解河小象 */
.top_video_container {
  margin-top: 156px;
  margin-bottom: -100px;
}
.top_video {
  width: 800px;
}
.top_video_container .top_video_title {
  font-size: 56px;
  color: #515558;
  line-height: 60px;
}

.top_video_container .top_video {
  height: 700px;
  width: 1180px;
  margin-top: 110px;
}
.top_video_container .top_video .video-player {
  /* height: 664px; */
  width: 800px;
}
.video-player {
  box-shadow: 46px 42px 5px #DEF0FF;
}
.top_video_container .top_video .video-js .vjs-big-play-button {
  border-radius: 50%;
}

.gradeList div:hover {
  background: #e3ebf6;
  line-height: 31px;
  width: 280px;
  text-align: left;
}
.classBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 379px;
  height: 421px;
  background-color: white;
  font-family: "MicrosoftYaHei";
  border-radius: 22px;
}

.fillInBox {
  background-color: white;
  width: 379px;
  height: 67px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fillP {
  font-size: 26px;
  color: #10b3fe;
  font-family: "Microsoft YaHei";
  font-weight: 550;
}

.fillInForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 192px;
}
.input1 {
  width: 296px;
  height: 44px;
  font-size: 14px;
  border-radius: 22px;
  border: 1px #bfbfbf solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.input1 input {
  width: 230px;
  height: 44px;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
  padding: 0 17px;
}
.downimg {
  width: 12px;
  height: 13px;
  cursor: pointer;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAgVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABtFS1lAAAAKnRSTlMAYvoM9fAV3wi+HUBbRzDKxToRBDXr6NTPJyLk2IuaK6dStKBsk350Za7Eky/uAAADD0lEQVR42u3c2VLiUBSF4XUEAiGIgDiAMsmgnvd/wA5d0mafhIppGfZOrf+aKvJxwbgOYIwxxhhjjDHGGGOMMcYYY4wxxhhjjDHGGGOMMcaYwkZJEsFGnWHSx7FmPm0NC/Va3vtB97gjLYb+nP9bu4GCEv9V3IHynP9qjoK2/tCgAdU5f6iJgmJvROL8dxHyLf13d4olWUer7Ab+eQSlictco6jYZ3pSKhGOVoSiRs9CovKlUThublFc485nelUokY4E+JGk3YWyShxZyUCzpMQh6sR6JVUcoeRlCjUJR1M6yiWPEyhJOoYo7/7NZ3pQIsk5qksWUFCho1zyKV4++7h6FRxSshSSMSqmxZEmJM0rS9xvLmb9/w+CKgcwr/C0LVLmEBLxRrMkdQ5gq0HiTvG0M7u+RDr6wCkkK5Sm1AHshKSHktQ6gHefrUSi2AFsfi5R7QglHziacgfwcR2JdCxwgnpCskFhBhyh5B0FmXCkkhufaYdcRhzASkhmOHMu/IhqVOLO+aXBrZBskcmUI5TM8S9jjlCyxiFrDiBpXkDiLvH14FBIlvcQncExhehcks9UYtMBjFs+09u9VQfQDyRWHaEk7lh1AIuHM0ncpX+YmQjJoHMWRxcXaPIoJA2rDmD64sXcw6oD6ArJc8OqI5W0Tzpccdf7KVlKniKrDiASktfIqgOIXsX9R1YdQPQkr8CqAxgFEquOcOD1Mv21I8KVkrOox6lVR04yseoIB14PC6uOcEzU6ldx6BoehhKrjpxkbNURzqKaQ6uOnCSx6ggHXjeJVUcqWVYaSTjFk+8qEqd6hL8WkpVZBzCXErOOUNIz6wC2wdzD7nGbmZSUOvQegJKSD7MOYCckG7OOcOD1btYBbKREOIwcECyU7Mw6woHXzKwjnEVtzTpCydysIxx4zc06gJXPFntv5Wh2ycAr61B/7D83JqqHYy+ph2MvqYcjM/Cy87cYR2dR9XDsJfVwAONmPRyppFUPB9Bv+31LmK+zi+PPWzDGGGOMMcYYY4wxxhhjjDHGGGOMMWalP5yJd0BB61pGAAAAAElFTkSuQmCC");
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upimg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAgVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABtFS1lAAAAKnRSTlMA+/cD7gc0Ft0K1B6T6mlOLSi3W/LNw72xYlRCPBIP5+PIq6SdcEgkgXiWS/MTAAACoElEQVR42u3b21LiQBSF4R0OQQ0iGEYFUVCO7vd/wCnLsky3XIRMutnN/N+1VbKAdLr3CgIAAAAAAAAAAAAAAAAAAPD/+HiRizDUm1e5AENV/XMtyTvop7fkk2z1y0NfkjbSb49JJ5npj/uuJKvUqqdkk8wzvYgkd5l63pNMssv0l7Wk57mjR2wkNT85XENJy6KjeglJcieH6yDpyKda1VPHh6Ri7L7yq8FGHSNJw8TPIbJWx0xSsL/VqlUhIt13dZRiX7HUqmUhn7pPWpXNxbpipVW3e/nSvXeT3Iltgys3x0S+9R9TSuLl6E3kR//BTbITu15u3Bxjqbp+06rOs1j16uaY5uK6/uMmWYhNr97rzI8k9f/Cojrv94v3mVlMUu8K8FeDsVjza02qva7ZUv8u4d9p9mKJf9+e17/3Lwuxw9tJaXnKbmxlJ4m/t52dtj++GogR61PPTZOeySQNzn/eGfLGRKU1VMe25qneXBIvx6HRnMVAOXdQx7DR5MtAOffh5Wg0izRQzm3VsWk05TZQzo3UsW7UOxgo52Z++9GkCTJQzpX/3keVmYFKq5W3szx/pdXSF3x27kpr19aFOjpvpfXcaW3p3J6zCFq0eTM7nC9J7m8vAmzXYsinLW/4hoEqrfiHic1pR4FAxVrsQ2aoYi3usT9csRZ/EBOqWIs9GgtUrLWnX7ecC1ishRm77iSAKAPo/kOtSitssRakmliIL0axFqIsSvV/HH+3UvzUj39/U7wOg68o9+qIucYPpE27LN7jF8Uq5Nn3Lov3aE+xDDmNmGfxHrba34acD5URH3+b9EJO7GYRH0gc90LOUEdBc/hJQk61t7qVWPJpyJ5hIfHkyf8sCwAAAAAAAAAAAAAAAAAAAAAAAAAAALDvLyEGHnuW3uAQAAAAAElFTkSuQmCC");
  background-size: cover;
  position: absolute;
  right: -20px;
  bottom: 2px;
}
.areacode {
  width: 50px;
  height: 26px;
  color: #bababa;
  border-right: 1px solid #bababa;
  margin-right: 13px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.areacode1 {
  width: 40px;
  height: 26px;
  color: #bababa;
  border-right: 1px solid #bababa;
  margin-right: 6px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding-right: 10px;
}
.fillBtn {
  width: 296px;
  height: 42px;
  background: rgba(19, 180, 254, 1);
  border-radius: 21px;
  font-size: 18px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #bababa;
}
.home {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 1200px;
  height: 421px;
  justify-content: flex-end;
  display: flex;
}
.select-enter-active,
.select-leave-active {
  transition: opacity 0.5s;
}
.select-enter,
.select-leave-to {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  opacity: 0;
}
.message {
  position: absolute;
  top: 52%;
  left: 75%;
  padding: 0 17px;
  box-sizing: border-box;
  width: 230px;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  color: #fefefe;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 23px;
  z-index: 600;
  overflow: hidden;
}
.orderTip {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 94px;
  padding-bottom: 119px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 16px;
  width: 605px;
  height: 340px;
  z-index: 1000;
  border: #e3ebf6 1px solid;
}
.orderTitle {
  margin-bottom: 60px;
  height: 47px;
  line-height: 47px;
  font-size: 47px;
  color: #71c5ff;
}
.orderMsg {
  width: 492px;
  height: 20px;
  line-height: 60px;
  color: #515558;
  font-size: 20px;
  text-align: center;
}
.closeOrder {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAhFBMVEUAAADJycm/v7++vr6+vr7Hx8e/v7/BwcG/v7/Dw8O+vr6+vr6/v7+/v7+/v7+/v7+/v7+/v7+/v7/AwMC/v7++vr6/v7/AwMC/v7+/v7+/v7/CwsK/v7+/v7+/v7/AwMDBwcG/v7+/v7+/v7+/v7++vr7AwMC+vr6+vr6+vr6+vr6/v7+9+e0GAAAAK3RSTlMABvgY3w2wE7wKxfHs1MuAuLSkIuSQdx7YnIgn56tcPzVxZGHPyUeYU0zNpVd7SgAAAblJREFUOMu9lNl2ozAQRI2Q2SQZDJjdeIszydT//9/ESmNJBDLn5CF6aSTu6Sq6UW9+YYX9+Rz/D/LeAobHGpL0/E2ugwDAo0PXBgqQXbyCMbDsGtK2qBSG2wLXl2Ct7xyNJaLtnHsXSPovljs0tXt0AnJvQWdksrD3hUDlACYD45afuEG3VooLlPn4FMF60Y5GzGcs1DJ56NQrf9VBiClliwOpNJYfv8FFP1RIqQiMUaYcxrnPkdN7KT8fXo3DoyaJO06nf3CiOD71AiKJo0xkTTHTJy/TpM9JhbwpHUWpg0XWHJndJy70K9yd/kbgJSKnn3c8tsWs2l4C4iznxQIYf4BJPANrLb2bcVk2I3fwvnzMNvnQ9SKXHKQOClvD7bS/B2kdkuYBo8XttYq3x+5J3qjZJwQT96K5ObkHXV3J/IkLNOeSIZPP/7a1/19DpmStmhQFen1zbm7lr/VnwvIpU+Gv981VGE2RFfI1rnP8+BzpMldBOU0q5OIA8HLI2aiom4WR0idmpJg+RfMh5bdsPqSoU4M19sJrxvDytmw87iQAwXd3LgC2f9+sr3MaqZKxQUWXcPOz9Q93ASPBvzinwwAAAABJRU5ErkJggg==");
  background-size: 40px 40px;
  cursor: pointer;
}
.indexhome {
  display: flex;
  width: 100%;
  height: 708px;
  justify-content: center;
  align-items: center;
  background-image: url("https://xcx.alicdn2.hexiaoxiang.com/web_static/home2.png");
  min-width: 1200px;
}
.indexEnjoy {
  width: 100%;
  height: 831px;
  background: rgba(247, 248, 250, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1200px;
}
.indexEnjoys {
  padding-top: 109px;
  width: 100%;
  height: auto;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 1200px;
}
.homeIndex {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 1200px;
}
.process {
  width: 1138px;
  height: 420px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
.processdiv {
  width: 235px;
  height: 396px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.processimg {
  width: 235px;
  height: 235px;
  border-radius: 50%;
  background-color: #e3ebf6;
  overflow: hidden;
}
.processtext {
  margin-top: 49px;
  font-size: 26px;
  margin-bottom: 21px;
}
.processtips {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: rgba(162, 162, 168, 1);
}
.science {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 581px;
  width: 1146px;
}
.sciencetitles {
  margin-bottom: 60px;
  width: 39xp;
  height: 106px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #515558;
}
.sciencetitle {
  font-size: 56px;
  font-weight: 500;
}
.sciencesubtitle {
  font-size: 26px;
  font-weight: 450;
}
.indexreview {
  padding-top: 60px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.indexfoot {
  width: 100%;
  height: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 108px;
  text-align: center;
}
.footorders {
  width: 100%;
  height: 75px;
  background: rgba(49, 49, 49, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.bigfootorder {
  width: 1000px;
  height: 75px;
  display: flex;
  justify-content: space-around;
}
.footorder {
  display: flex;
  width: 950px;
  height: 75px;
  align-items: center;
  justify-content: space-between;
}
.footfillInForm {
  width: 740px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 75px;
}
.group {
  position: absolute;
  width: 190px;
  height: 100px;
  display: flex;
  align-items: center;
  bottom: -28px;
  left: 15px;
}
.footinput1 {
  width: 190px;
  height: 44px;
  font-size: 14px;
  border-radius: 22px;
  border: 1px #bfbfbf solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 17px;
  background: white;
  position: relative;
}
.footinput1 input {
  width: 140px;
  height: 44px;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
  position: relative;
}
.footfillBtn {
  width: 205px;
  height: 42px;
  background: rgba(19, 180, 254, 1);
  border-radius: 21px;
  font-size: 18px;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
}
.footorderimg {
  width: 182px;
  height: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 12px;
  position: relative;
}
.orderfootimg {
  width: 182px;
  height: 242px;
  position: absolute;
  bottom: 0;
}
.footfix {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0px;
  z-index: 999;
  display: none;
}
.footselect {
  position: absolute;
  bottom: 70px;
  left: 0px;
  width: 190px;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #e3ebf6 1px solid;
  background-color: white;
  opacity: 1;
}
.footgradeList {
  width: 190px;
  height: 260px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 20px;
}
.footgradeList div {
  width: 190px;
  line-height: 26px;
  text-align: left;
  cursor: pointer;
}
.footgradeList div:hover {
  background: #e3ebf6;
  line-height: 31px;
  width: 190px;
  text-align: left;
}
.homeFoot {
  width: 100%;
  height: 386px;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footmessage1 {
  position: absolute;
  width: 114px;
  height: 36px;
  background: rgba(19, 180, 254, 1);
  border-radius: 8px;
  bottom: 87px;
  left: -26px;
  font-size: 14px;
  color: white;
  line-height: 40px;
}
.footmessage1:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 27px;
  top: 36px;
  border-left: 10px solid transparent;
  border-top: 10px solid rgba(19, 180, 254, 1);
  border-bottom: 10px solid transparent;
}
.footmessage1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 37px;
  top: 36px;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(19, 180, 254, 1);
  border-bottom: 10px solid transparent;
}
.footmessage2 {
  position: absolute;
  width: 134px;
  height: 36px;
  background: rgba(19, 180, 254, 1);
  border-radius: 8px;
  bottom: 87px;
  left: -26px;
  font-size: 14px;
  color: white;
  line-height: 40px;
}
.footmessage2:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 27px;
  top: 36px;
  border-left: 10px solid transparent;
  border-top: 10px solid rgba(19, 180, 254, 1);
  border-bottom: 10px solid transparent;
}
.footmessage2:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 37px;
  top: 36px;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(19, 180, 254, 1);
  border-bottom: 10px solid transparent;
}
.hB1 {
  background-color: #71c5ff;
  height: 708px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-end;
}
.hB2 {
  background-color: #ffb373;
  height: 708px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-end;
}

.picture {
  width: 900px;
  height: 708px;
  position: relative;
  overflow: hidden;
}
.pictureS {
  width: 755px;
  height: 663px;
  position: absolute;
  bottom: -150px;
  left: 100px;
}
.ele {
  width: 100%;
  height: 708px;
  position: relative;
  min-width: 1200px;
}
</style>

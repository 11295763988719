<template>
  <div class="newworks">
    <h1 class="worktitle">最新作品</h1>
      <div class="workbottom" >
        <transition name="slide1-fade" mode="out-in">
          <div v-if="showWorks" class="showBox">
            <div class="showworks" v-for="(item,index) in patchList" :key="index">
              <img :src="item.url" class="showtop"/>
              <div class="showbottom">
                <div class="showdiv1">
                  <img :src="item.head" class="showdiv1img">
                </div>
                <div class="showdiv2">
                  <p>{{item.name}} {{item.info}}</p>
                  <p>辅导老师：{{item.teacher}}</p>
                </div>
                <div class="showdiv3">{{item.time}}</div>
              </div>
            </div>
          </div>
        </transition>
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      workList: [],
      showWorks: false,
      patchList: []
    };
  },
  created() {
    this.getWorksInfo();
  },
  methods: {
    // 设置时间格式
    setFormateTime(creat) {
      let date = new Date().getTime();
      var time = (date - creat) / 1000 / 3600 / 24 / 365;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + "年前";
      }
      time = time * 365 / 30;

      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + "月前";
      }
      time = time * 30;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + "天前";
      }
      time = time * 24;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + "小时前";
      }
      time = time * 60;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + "分钟前";
      }

      return "刚刚";
    },
    //定期更新展示的作品
    setNewWork() {
      let that = this;
      let i = 1;
      let newWorksList = this.workList;
      let len = newWorksList.length;
      let num = parseInt(len / 3);
      let changeWork = setInterval(function() {
        if (i < num) {
          that.showWorks = false;
          let startNum = 3 * i;
          that.patchList = newWorksList.slice(startNum, startNum + 3);
          i = i + 1;
          setTimeout(() => {
            that.showWorks = true;
          }, 800);
        } else {
          i = 0;
        }
      }, 8000);
    },
    //获取作品信息
    getWorksInfo() {
      var that = this;
      this.axios
        .post("/api/task/recommend", { per_page: 30 })
        .then(res => {
          if (res.data.code == 20000) {
            let tasks = res.data.data.tasks;
            that.workList = tasks.reduce(function(pre, val) {
              let obj = {};
              obj.head = val.avatarUrl;
              obj.name = val.nickName;
              obj.url = val.photoUrl;
              obj.teacher = val.teacher;
              obj.grade = val.info;
              obj.time = that.setFormateTime(val.timestamp);
              pre.push(obj);
              return pre;
            }, []);
            that.patchList = that.workList.slice(0, 3);
            that.showWorks = true;
            console.log(that.workList);
            that.setNewWork();
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.newworks {
  width: 1348px;
  height: 719px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.workbottom {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 549px;
}
.showworks {
  width: 371px;
  height: 495px;
  border-radius: 16px;
  background-color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.worktitle {
  font-size: 56px;
  color: #515558;
  font-weight: 500;
}
.showbottom {
  height: 20%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.showtop {
  height: 495px;
  width: 100%;
  border-radius: 16px 16px 0 0;
}
.showdiv1img {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  position: absolute;
  left: 27px;
  bottom: 19px;
}
.showdiv1 {
  width: 53px;
  height: 53px;
  border-radius: 50%;
}
.showdiv2 {
  width: 162px;
  height: 52px;
  font-size: 18px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  line-height: 26px;
}
.showdiv3 {
  width: 66px;
  height: 46px;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  color: rgba(255, 255, 255, 1);
}
.slide1-fade-enter-active {
  transition: all 0.5s ease;
}
.slide1-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide1-fade-enter,
.slide1-fade-leave-to {
  opacity: 0;
}
.showBox {
  display: flex;
  width: 1448px;
  height: 719px;
  justify-content: space-around;
}
</style>
<template>
  <div class="container">
    <div :class="left" @click="last()">
    </div>
      <div class="box"  >

          <div class="wrapper" v-for="item in reviewlist" :style="change" :key="item.name" >
            <div class="child">
              <div class="hi">
                <div class="head">
                  <img :src="item.head"/>
                </div>
                <div class="introduction">
                  <p class="name">{{item.name}}</p>
                  <p>{{item.age}} {{item.place}}</p>
                  <p class="times">{{item.class}}</p>
                  <p class="times">加入河小象时间：{{item.time}}</p>
                </div>
              </div>
              <div class="photo">
                <div class="before">
                <img :src="item.before"/>
                </div>
                <div class="after">
                  <img :src="item.after"/>
                </div>
              </div>
              <div class="text">
                <div class="beforejoin">加入河小象前</div>
                <div class="afterjoin">加入河小象后</div>
              </div>
            </div>
            <div class="parent">
              <img @click.stop="toMore" :src="item.review"/>
            </div>
          </div>

      </div>
    <div :class="right" @click="next()"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      left: "leftb",
      right: "rightB",
      a: 0,
      b: 0,
      change: "",
      reviewlist: [
        {
          head: "https://xcx.alicdn2.hexiaoxiang.com/web_static/a1.png",
          name: "崔浩东",
          age: "三年级",
          place: "济南",
          class: "河小象写字营VIP60营",
          time: "2018年4月4日",
          before: "https://xcx.alicdn2.hexiaoxiang.com/web_static/a2.png",
          after: "https://xcx.alicdn2.hexiaoxiang.com/web_static/a3.png",
          review: "https://xcx.alicdn2.hexiaoxiang.com/web_static/a4.png",
          count: 0
        },
        {
          head: "https://xcx.alicdn2.hexiaoxiang.com/web_static/b1.png",
          name: "张奕晨",
          age: "大班",
          place: "上海",
          class: "河小象写字营VIP60营",
          time: "2018年3月28日",
          before: "https://xcx.alicdn2.hexiaoxiang.com/web_static/b2.png",
          after: "https://xcx.alicdn2.hexiaoxiang.com/web_static/b3.png",
          review: "https://xcx.alicdn2.hexiaoxiang.com/web_static/b4.png",
          count: 1
        },
        {
          head: "https://xcx.alicdn2.hexiaoxiang.com/web_static/c1.png",
          name: "吴雨伦",
          age: "三年级",
          place: "杭州",
          class: "河小象写字营B23班",
          time: "2018年3月16日",
          before: "https://xcx.alicdn2.hexiaoxiang.com/web_static/c2.png",
          after: "https://xcx.alicdn2.hexiaoxiang.com/web_static/c3.png",
          review: "https://xcx.alicdn2.hexiaoxiang.com/web_static/c4.png",
          count: 2
        }
      ]
    };
  },
  mounted() {},
  methods: {
    toMore() {
      window.open(
        "https://mp.weixin.qq.com/mp/homepage?__biz=MzI4NzczNzQwNQ==&hid=1&sn=a36765eea27732997c5a81cb335a65ba&scene=18#wechat_redirect"
      );
    },
    next() {
      if (this.a + this.b == -1300 * (this.reviewlist.length - 1)) {
        this.right = "rightb";
      } else if (this.a + this.b == -1300 * (this.reviewlist.length - 2)) {
        this.right = "rightb";
        this.b += -1300;

        let c = this.a + this.b;

        let right = "transform:translateX(" + c + "px)";

        this.change = right;
      } else {
        //换回图标
        this.left = "leftB";
        this.b += -1300;

        let c = this.a + this.b;

        let right = "transform:translateX(" + c + "px)";

        this.change = right;
      }
    },
    last() {
      if (this.a + this.b == 0) {
        this.left = "leftb";
        // 更改图标
      } else if (this.a + this.b == -1300) {
        this.left = "leftb";
        this.a += 1300;

        let c = this.a + this.b;

        let left = "transform:translateX(" + c + "px)";

        this.change = left;
      } else {
        // 图标换回来
        this.right = "rightB";
        this.a += 1300;

        let c = this.a + this.b;

        let left = "transform:translateX(" + c + "px)";

        this.change = left;
      }
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.leftB {
  cursor: pointer;
  width: 91px;
  height: 91px;
  z-index: 101;
  margin-top: 20%;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAbFBMVEUAAACUlJSZmZmZmZmZmZmZmZmampqZmZmampqZmZmZmZmZmZmZmZmZmZmampqampqampqampqZmZmampqbm5uampqZmZmcnJyZmZmYmJiZmZn////MzMykpKTx8fHc3Ny2trb4+Pjv7+++vr5qzdsUAAAAGnRSTlMAB97w45JqTB3R+OvVxbqxnI5xUUU/FBLSJz/VgGEAAAGdSURBVFjDrNIJUsJAEIXhzqxkX8gCLyKo97+jQqGiJJPJpL8D/NU1b2hB3xpdxCJNklTEhTZtT+EiW8d4Etc2ohBdJTFDVt3q44yAkzDRmlyjsEg13kkr4EVY8rEr4a3c0aK9xApyT26Rxkra+ZJDjtXywfF8GQJksw95VAiijjP3KQRSkzcOGYJlw8S+OTbIn7fW2EQ//WdstP83iMRG8u8wJTYr6YEFA/uwsAAD8bt0Ax+X8/kCl+bnQAUPL+OXDzio7xONd298h4u5B4V3b3yDi6Cbzr83nuHU0VXl33s9wam6TSLZepDXWSxb7/65a8YeaiKKGXuIiXrOHtBTy9pDS4a1B0OatQdNBWsPBR1YeziQYO1BUMraQ0oJpp2Cekg+m7NDIwBgEIaimg2y/6QIDBLxBAv0rldKkh9+IL8yfxQ9NsUHm389vhz4+uILVksAFykuo1zotRXhZknbOW44uSXWpp3HCh18dDTj4VHHWx3AOSLQEENjFg2CNKrSME3jPgwkNTLFUFdjZwzGLbr/UC6s+iNTf+RSfzQc7vwbvO3XPQAAAABJRU5ErkJggg==");
  background-size: cover;
}

.rightB {
  cursor: pointer;
  width: 91px;
  height: 91px;
  z-index: 101;
  margin-top: 20%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAULSURBVHhe7Z1JSzNLFEDvi+jngPNCHBFFBXFAXKi4Edw44NYf6VYUl25EXYg4IKgg4ogLZ5yR771TrzoaM3RMOumqpA80VipBuIdKVVdX1c0/f/9DfOL19VWur6/l/v5e7u7u1PX8/CwfHx/hCwoLC8NXaWmpVFVVqauyslJqa2uluLhYfc4Psirw8/NTzs/P5eLiQs7OzuTm5ka/kx41NTXS1NQkDQ0N0tjYKAUFBfqdzJMVgZeXl3J4eChHR0fy/v6uazNDUVGRtLW1SUdHh9TX1+vazJExgbS2/f192draksfHR12bXcrLy6W/v1+6uroy1io9F4i4vb092d7elqenJ13rL2VlZdLX1yfd3d2ei/RU4MnJiaysrPjW4tygRY6OjkpLS4uuSR9PBNLSEHd8fKxrzKa1tVWJpGWmS9oCkba8vJzxwcFrGGzGxsaUzHRIWSB93fr6uuzu7uoaO+np6ZGhoaGU+8aUBL69vcnS0pJcXV3pGrupq6uTiYkJ+fPnj65Jnl8LpL9bXFyU29tbXZMbVFdXy9TU1K/7xV8JZKq1sLBgzO2J1yBvenpaTROTJaT/uoK0XJYHqcSYlED6PL62uSzPgRiJlZiTwVUgoy0DRq71eYkgVmImdjdcBXKrkiuj7W8gZmJ3I6FAbpJtv89LB2J3m13FFUhfwAwj38FBor4/rkDmtrZNzzIBDnARj5gCeapiy4OBbIALnMQiSiAjTyLj+QpOYo3KUQJ5GOrH87zm5mYZHx9XF2XTwAlufhIhEMM8Sc42rF9MTk5Ke3u7uigPDg7qd80BNz9bYYRA1jD8mG0MDAzo0hcINE0ibnD0nQiBLAD5QUlJiS5FYqLEn47CAll69GstgzXieJgmEUe4cggLZN3WL9bW1uTh4UG/isY0id9dKYF0jCx6+wV9y/z8vDUSceUMJkog2y38nnXYJBFXOAMlkL0qJmCTRMeZEpioE882tkh0nIXYYubVLimvsEEiznAXYn+eidggEXchNjeaiukScRdiqdJkTJaIO+MFgqkSlUD2JNuAiRJxF3I2ctuAaRJxZ5VAMEmilQIhWYlebKBMhBKoywEpEuLwim3QsmZmZqSiokLXRLOxsaFaaibBnXUCk5XHlWmsE2iSPFACOXtmA6bJA9yFfrMb0y9MlAe4M16gqfJACeTIqKmYLA9wF+K8rYmYLg9wF+KwMudtTcIGeTjDnZqJcFjZFGyQB44zJZCT3iZgizxwnCmBHJPn8J2f2CQPVzgDJZCDdhyT9wub5AGunMOJ4acx7NHzi+HhYWvkwXdXYYEkaOBEtx8kGsRMk4ej78kswgKBBA1+8PLyokuRmCYPfjqKEEh2C/qjbLO5ualLX5goDzc4+k7UcdednR1ZXV3Vr7IHG8s7OztV+eDgQE5PT1XZJEZGRqS3t1e/+p8ogex7m5ubMzbzhl/Q983OzkalBoj4CgMfIKNFQCQ4iZVXIUogkFcl3WwWuQQu4uWaiSkQMO737MQEcJDoGxlXICMOeVXyHRwkujOJKxBouuRVyVeI3a0rSygQSEpDXpV8g5iJ3Q1XgYw8JKUhr0q+QKzEHGvU/YmrQCCjTypJaWyEGIk12SxGSQkE/jFJaXJZYioxBqmfNFlJ/eQQJB/7IiWBwJw5SH+XhkCHIAFjmgKBfjFIAeoBQRJaD6BvDNIgewAig0TcHhGkgvcIWmXwYwQeYv/PYYj8CystkbHtrnQVAAAAAElFTkSuQmCC");
  background-size: cover;
}
.leftB:hover {
  cursor: pointer;
  width: 91px;
  height: 91px;
  z-index: 101;
  margin-top: 20%;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAaVBMVEUAAADOzs7MzMzNzc3Ozs7Nzc3MzMzPz8/JycnNzc3MzMzMzMzMzMzNzc3MzMzMzMzMzMzNzc3Nzc3MzMzPz8/Kysr////Ly8vMzMwrjfd7rOFAlfG5xNFhouidudiAruCQtdu9xtDAx89I7Ji0AAAAGHRSTlMAB+DTkU7wHRP46+XcxbqxnHFsaEU/ASdoXxwfAAABkklEQVRYw6zUB04DMRCF4XGNt/fNCyGh3P+QoEUgEWe9bt8Bfo09lumAmUfZCl4WRclFK8fZUDympIBFSMUowrr0Gjt0v6yhw001nOqJheSGCoeqwTupOLxwRT5OHbx1J4/xNALooyGZRCDJyME0CNYYx/UJRBC7F3nmiMLPO/NxROJPZzQC0YQhC2uQoLF3LZFEWu8ZidTDQjQS6f+L6ZCssw6c8dCMIwPO6NcAH/fb7Q6X4W/ACh5eLt/e4VAx+jF59y5XuEy0WWu/3gYu9boFl4DeDU7LFuz9e9cPOPXbSrR/7xVumhGRSuvZj1um9exPR2TsQRCZlJ7N0Jy1h5nGrD2MJLP2IKnN2kNLImsPgnjWHjiV2PEW00NJBZ77jOqh+CrOTkwAgGEYBu6/tXcwB1qgUJr6UfiB/Mr8UfjY8MHmX4+LA5cvLrDaArhJcRvlRq+jCA9LPM7pwMkjsQ7tvFbo4qOrGS+Put7qAs4RgYYYGrNoEKRRlYZpGvdhIKmRKYa6GjtjMI7Rfb9ceNcfAyZf1SKWk9zNAAAAAElFTkSuQmCC");
  background-size: cover;
}
.rightB:hover {
  cursor: pointer;
  width: 91px;
  height: 91px;
  z-index: 101;
  margin-top: 20%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAVFBMVEXMzMz///8rjfd7rOH7+/vi4uLS0tLPz89AlfG5xNHq6ur6+vry8vLw8PDb29vU1NTW1tbv7+/Y2NjR0dH39/dhouidudhClvBiouiQtdu9xtDAx884UkkuAAABj0lEQVRYw8zTyVbDMAyF4ZsiyeAxQ1um939PzCmnUJo4jqNFf+2/o81FV4685ZjE5CRFtp66cihhgXvc1XOgJtCzwUKG/WbQCoqJ3QKSnbDaZKkWDIKqJFSBLqK66NbBwWBDZlgDGRvjIkgjNjfSMugSGkpuCTwKmpLjPOgEjYmbAymhuUQz4IgdjfcgY1f8Hxyws+EWdAY7M+4GjNhd/AsGKBR+QRIoJHQFLUqdT6czarJXcEKh90PuCRVNF3DtwddDtWh/QEGprNWKcgE9ir1tEH0GV0f38Vwv8jdIBuVe6kVDGQyAnhgyyFAUOYM9FMW+AwGaIsFDVfSwUBUtGKoiI0JVjOihKiYIVEWBgapo8jWJnw8KfjVnh1YAwCAMRB2VZf9pQSIRX9AN+kpJcllc2T/Kx2PDB5t/Pb4c+PriC1ZLABcpLqNc6LUV4WZJ2zluOLUl5qadxwodfHQ04+FRx1sdwDUi4BBDYxYNgjSq0jAN4z4NJDUyxVBXY2cLxjG6v1AujPoj4/WJ3NQfBa/zFOqJlLHUAAAAAElFTkSuQmCC");
  background-size: cover;
}
.leftb {
  cursor: pointer;
  width: 91px;
  height: 91px;
  z-index: 101;
  margin-top: 20%;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAXVBMVEUAAADMzMzOzs7Nzc3Nzc3Ozs7Nzc3Pz8/JycnMzMzNzc3MzMzMzMzMzMzNzc3MzMzMzMzMzMzNzc3MzMzPz8/Kysr////Ly8vMzMzMzMzl5eXY2NjPz8/c3NzU1NS22lFxAAAAGXRSTlMA3wdO0ZJuHRPw+Ovl1cW6sZyOaEU/ASfxsjISKQAAAbxJREFUWMPFmQtywyAMRMEGB+L/L8mmde5/zKbpb1rVGCx1+g6ws5YYIy1qAzcVtjW6PB5LbVpbTE7tJ8sHA4IZ8kzt4DT3Hiv4fj6lmhs1gugxS5ErKmxSFdGSuUYUOlcxHDpE0x0i7Hkk4LdMZhaJ2GAlXYNkGhcoX40d1KuFPGvsQp9X/GnsRP/q0dXYTe0UIWvAoKG9tmBhyXkGk/xHQzyY+O+N6cCmIx8s+NGZhgD6q9MFYrgtyw0hik+DFSJ4ut4JKlYfFsc4vQcIMb7fbzpeLyyo3+7COUFvQZD5IdjH610Rpn+05BKv94ww/rUtOc8fPdwDwx9huAsahj+CUcpx/FGcmjj+KJMqOP4ohbIcfxSrWo4/SqsMzx9ts+b4o2hVcvxRSnXECsRfFMdNwSVVsBQtIUr5phiIKpqog72kHGwLSHq08j+HCaKKE/nBMuvoyBXA82j+4pLKwVKk12jmAak6+oyMIjyPvfiwJD7OSQ+c4iOx9NAuvlZILz7iq5n88ii/3sov4PIRgXyIIR+zyAdB8lGVfJgmH/fJB5Lykal8qCsfO8sH4zS6v2CFSz+f/v9xYe/zxwsS3ufOMgF5ZQAAAABJRU5ErkJggg==");
  background-size: cover;
}

.rightb {
  cursor: pointer;
  width: 91px;
  height: 91px;
  z-index: 101;
  margin-top: 20%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAKlBMVEXMzMz////l5eXQ0NDZ2dnT09P7+/vw8PDq6urc3Nzy8vLW1tbg4OD39/ers8tCAAABrUlEQVRIx42Xv07CUBSHv1Aw/FswQonEBBoS14ZBY+JQfQJYTIwLjfEBqi8AqxOLO26OMLib+AS+kbUi0ttb+vutfPk43HDPOZdWOp234bvv3F0/h8YHBvi4YpPG6R7w+JOdXL7mgd0xqVTmdrDrY8SZ20A38RnOKAt2FlhSCzPgE9acm+AhORmkwU6QB5bDFPhAbm52wZNxPlj5+gcTYYEyATuG0FCGW/CIvbnagv39YOkPdClItAHvi8CzDbgoAmu/oEthogRsF4OTBJyRyu1LFqwmoJ8+Xs8LMqDzA3aNQ/O8IZnMY7BtgjblJAaXxlF4NmU9Bj+McjybshmD5iW9sCmdFi4oyojsrRrZlAN6SMo1U0Co8oAlkrLODJQqq/SRlCUCUKoss0JSNhiDUmUFH0npkMReZR8howzo28HMV+s/ZiUJaRBIQsr0JSEl/U+xlITUmUpCDuhJQtb65XIlIREtXxE6SUsRhE25ScltT26kamuWm708PuSBpI44eWjKY1gd7PKqIC8f6jojL0jqyiUvcepaqC+a+uqqL8P6eq0v7PoTQH9UyM+Ub1zleE4LOdAKAAAAAElFTkSuQmCC");
  background-size: cover;
}
.photo {
  display: flex;
  justify-content: space-around;
  margin-top: 47px;
}
.text {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.before {
  width: 260px;
  height: 345px;
}
.before img {
  width: 260px;
  height: 345px;
}
.after img {
  width: 260px;
  height: 345px;
}
.name {
  font-size: 30px;
  line-height: 40px;
}
.after {
  width: 260px;
  height: 345px;
}
.beforejoin {
  width: 200px;
  line-height: 20px;
  font-family: MicrosoftYaHei;
  color: rgba(81, 85, 88, 1);
  font-weight: 600;
  font-size: 20px;
}
.afterjoin {
  width: 200px;
  line-height: 20px;
  font-family: MicrosoftYaHei;
  color: rgba(81, 85, 88, 1);
  font-weight: 600;
  font-size: 20px;
}
.child {
  margin-top: 47px;
  width: 755px;
  height: 772px;
  background: rgba(238, 246, 250, 1);
  border-radius: 16px;
  z-index: 98;
  position: absolute;
  left: 60px;
}
.parent {
  width: 461px;
  height: 880px;
  color: black;
  z-index: 99;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 60px;
  cursor: pointer;
}
.parent img {
  width: 461px;
  height: 880px;
}
.introduction {
  width: 400px;
  height: 144px;
  font-size: 18px;
  line-height: 30px;
  margin-top: 56px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.hi {
  display: flex;
  padding-top: 60px;
  padding-left: 61px;
}
.head {
  width: 213px;
  height: 213px;

  margin-right: 44px;
}
.head img {
  width: 213px;
  height: 213px;
  border-radius: 50%;
}

.box {
  height: 892px;
  width: 1300px;
  display: flex;
  overflow: hidden;
}
.wrapper {
  height: 892px;
  width: 1300px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  flex: 0 0 1300px;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  position: relative;
}
.container {
  height: 892px;
  width: 1482px;
  font-family: MicrosoftYaHei;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  overflow: hidden;
  transform: scale(0.8);
}
.times {
  width: 320px;
}
</style>

<template>
  <div class="wrpp1">
    <div class="appmain">
      <div class="appcontainer">
        <div class="hi106">
          <p class="h1">学习软件支持各大平台</p>
          <p class="h3">随时随地，想学就学</p>
        </div>
        <div class="download">
          <div class="phone">
            <p>微信小程序</p>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/mine.png" class="phonecode">
            <p class="phonetext">微信扫一扫，即可体验写字小程序</p>
          </div>
          <div class="phone">
            <p>苹果版</p>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/ios.png" class="phonecode">
            <div
              class="phoneBtn"
              @click="handleCopy('https://itunes.apple.com/cn/app/%E6%B2%B3%E5%B0%8F%E8%B1%A1/id1436282840?mt=8',$event)"
            >复制并分享给好友</div>
          </div>
          <div class="phone">
            <p>安卓版</p>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/android.png" class="phonecode">
            <div
              class="phoneBtn"
              @click="handleCopy('https://android.myapp.com/myapp/detail.htm?apkName=com.mobcoreapp&ADTAG=mobile',$event)"
            >复制并分享给好友</div>
          </div>
        </div>
      </div>
    </div>
    <div class="qrcodeBox1">
      <div class="phone">
        <p>iPad版</p>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/ipad.png" class="phonecode">
        <p class="phonetext">打开iPad App Store 搜索"河小象写字"</p>
      </div>
      <div class="phone1" @click.stop="goLogin">
        <p>电脑版</p>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/pc.png" class="pccode">
        <p class="phonetext">点击此处 跳转电脑版河小象</p>
      </div>
    </div>
  </div>
</template>
<script>
import clip from "../utils/clipboard";
export default {
  data() {
    return {};
  },
  methods: {
    goLogin() {
      let key = localStorage.getItem("key");
      if (!key) {
        this.$router.push({ path: "login" });
      } else {
        this.$router.push({ path: "classroom" });
      }
    },
    handleCopy(text, event) {
      clip(text, event);
      console.log("clicp");
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;

  padding: 0;
}
.wrpp1 {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.foots {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  background-color: #333333;
  justify-content: center;
  margin-top: 208px;
}
.qrcodeBox1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.phoneBtn {
  width: 164px;
  height: 40px;
  background: rgba(19, 180, 254, 1);
  border-radius: 20px;
  font-size: 15px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 40px;
}
.h1 {
  height: 56px;
  line-height: 56px;
  font-size: 56px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
  margin-bottom: 10px;
  width: 570px;
}
.h3 {
  height: 26px;
  line-height: 26px;
  font-size: 26px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
}
.hi106 {
  height: 106px;
  width: 570px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.phone {
  height: 346px;
  width: 300px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.phone1 {
  height: 346px;
  width: 300px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.phone p:first-child {
  font-size: 30px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
}
.phone1 p:first-child {
  font-size: 30px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
}
.phonecode {
  margin-top: 42px;
  width: 200px;
  height: 200px;
}
.pccode {
  margin-top: 44px;
  width: 142px;
  height: 127px;
}
.phonetext {
  margin-top: 19px;
  font-size: 20px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
  width: 250px;
}
.phone p:last-child {
  font-size: 20px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
  width: 300px;
}

.download {
  width: 100%;
  height: 496px;
  margin-top: 112px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  min-width: 1200px;
}
.appcontainer {
  width: 1200px;
  /* height: 695px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appmain {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.toBottom {
  position: absolute;
  bottom: 0;
}
</style>
